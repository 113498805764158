<template>
  <StatusPage
    class="reimbursement-success-page"
    status="success"
    title="Good news! We see you’ve selected Reimbursement."
    restrict-title-width
  >
    <p class="reimbursement-success-page__text">
      Please have device repair performed and upload or email us your repair receipt.
    </p>

    <GoToMyDevicesButton />
  </StatusPage>
</template>
<script>
import StatusPage from '@/components/shared/StatusPage';
import GoToMyDevicesButton from '@/components/shared/GoToMyDevicesButton';

export default {
  components: {
    StatusPage,
    GoToMyDevicesButton,
  },
};
</script>
<style lang="scss" scoped>
.reimbursement-success-page {
  &__text {
    max-width: 390px;
    margin: 10px 0 34px;
  }
}
</style>
